module.exports = {
  // Site domain. Do not include a trailing slash!
  siteUrl: 'https://www.davidtuite.com',
  // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
  postsPerPage: 12,
  // This allows an alternative site title for meta data for pages.
  siteTitleMeta: "David Tuite's Blog",
  // This allows an alternative site description for meta data for pages.
  siteDescriptionMeta: 'Product development, product management, design and engineering',

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  shortTitle: 'David Tuite', // Used for App manifest e.g. Mobile Home Screen
  siteIcon: 'favicon.png', // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#15171A', // Used for Offline Manifest
};
